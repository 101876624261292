import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./banner.scss";
import Over from "../../assets/new_imgs/foods/Group-38666-1.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const BannerHome = ({
  className,
  headerImages,
  isFirst,
  isVideo,
  isOverllay,
  bannerWithAdditional,
  imageOverllay,
  isBookingList
}) => {
  let openLink = true
  if (isBookingList) {
    openLink = false
  }
  if (bannerWithAdditional) {
    openLink = false
  }
  console.log("headerImages", headerImages);

  const [intervalz, setIntervalz] = useState(isVideo ? 10000 : 3000);
  const [open, setOpen] = useState(false);
  const [additionalImage, setAdditionalImage] = useState("");
  const [imgLink, setImgLink] = useState(null);
  const location = useLocation();
  const [videoDir, setVideoDir] = useState(isVideo ? 7000 : 3000);
  const [cuurntType, setCuurentType] = useState(isVideo ? "video" : "image");
  const [autoPlay, setAutoPlay] = useState(true); // State to control autoplay

  const handleClose = () => {
    setOpen(false);
    setAdditionalImage("");
    setImgLink("");
  };

  const handleSelect = (image, link) => {
    if (bannerWithAdditional) {
      if (image) {
        setAdditionalImage(image);
        setImgLink(link);
        setOpen(true);
      }
    }
  };

  const handleVideoDuration = (e) => {
    console.log("e.target.duration * 1000", e.target.duration * 1000);

    setVideoDir(e.target.duration * 1000); // Get duration in milliseconds
  };

  console.log("int", intervalz);


  console.log("location.pathname", location.pathname);
  useEffect(() => {
    console.log("der***", videoDir);
    if (isVideo) {
      setIntervalz(videoDir)

    } else {
      setIntervalz(3000)
    }
  }, [videoDir])


  const imageref = useRef();
  const [height_res, setheight_res] = useState();
  useEffect(() => {
    if (imageref.current) {
      setheight_res(location.pathname.includes("qr-code") || location.pathname.includes("MyOrders") || location.pathname.includes("AboutUs") ? Math.floor(imageref.current.clientWidth / 1.18) : "auto");
    }
  }, [headerImages]);

  const onChange = (index, item) => {
    if (item?.props?.children[0]?.type === "video") {
      setIntervalz(videoDir);
      setCuurentType("video");
    } else {
      setIntervalz(3000); // Set interval for images
      setCuurentType("image");
    }
  };

  // Event handlers for hover
  const handleMouseEnter = () => {
    setAutoPlay(false); // Pause carousel
  };

  const handleMouseLeave = () => {
    setAutoPlay(true); // Resume carousel
  };

  return (
    <div className={className} ref={imageref}>
      <Carousel
        className="banner noSelect mb-2"
        swipeable={true}
        onChange={onChange}
        autoPlay={isBookingList ? false : autoPlay} // Bind autoplay to state
        interval={intervalz}
        infiniteLoop={true}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
        onMouseEnter={handleMouseEnter} // Stop carousel on hover
        onMouseLeave={handleMouseLeave} // Resume carousel on hover out
      >
        {headerImages?.map((slide, idx) => {
          if (
            ["mp4", "avi", "mkv", "mov", "webm"].includes(
              slide.image.split(".").pop().toLowerCase()
            )
          ) {
            return (
              <div
                className="carousel-item-custom noSelect"
                data-interval={intervalz}
                onClick={() => handleSelect(slide?.additional_image, slide?.link)}
                style={{ height: height_res }}
                key={idx}
              >
                <video
                  width="100%"
                  height="165"
                  autoPlay
                  muted
                  playsInline
                  loop={true}
                  onLoadedMetadata={handleVideoDuration} // Set interval based on video duration
                >
                  <source src={slide.image} type="video/mp4" />
                </video>
                {isOverllay && <img src={Over} alt="" className="ove" />}
                {imageOverllay && <img src={Overllay} alt="" className="overlay" />}
              </div>
            );
          }

          return (slide.link && openLink) ? (
            <a href={slide.link}>
              <div
                className="carousel-item-custom noSelect"
                data-interval={3000}
                onClick={() => handleSelect(slide?.additional_image, slide?.link)}
                style={{ height: height_res }}
              >
                <img src={slide.image} alt="Slide" ref={imageref} />
                {isOverllay && <img src={Over} alt="" className="ove" />}
                {imageOverllay && <img src={Overllay} alt="" className="overlay" />}

              </div>
            </a>
          ) : (
            <div
              className="carousel-item-custom noSelect"
              style={{ height: height_res }}
              data-interval={3000}
              onClick={() => handleSelect(slide?.additional_image, slide?.link)}
            >
              <img src={slide.image} alt="Slide" ref={imageref} />
              {isOverllay && <img src={Over} alt="" className="ove" />}
              {imageOverllay && <img src={Overllay} alt="" className="overlay" />}

            </div>
          );
        })}
      </Carousel>

      {bannerWithAdditional && (
        <Modal
          show={open}
          onHide={handleClose}
          className={`food-modal  banner_popup p-0`}
          style={{
            minHeight: "150px",
            padding: "0px !important",
            display: "flex !important",
            alignItems: "center !important",
            paddingRight: "0 !important",
          }}
        >
          <Modal.Body style={{ padding: "20px 10px", height: "auto" }}>
            {imgLink !== null ? (
              <>
                <a href={imgLink}>
                  <div>
                    {["mp4", "avi", "mkv", "mov", "webm"].includes(
                      additionalImage?.split(".").pop().toLowerCase()
                    ) ? (
                      <video
                        width="100%"
                        height="165"
                        autoPlay
                        muted
                        playsInline
                        loop={false}
                        onLoadedMetadata={handleVideoDuration} // Set interval based on video duration
                      >
                        <source src={additionalImage} type="video/mp4" />
                      </video>
                    ) : (
                      <img src={additionalImage} alt="Slide" />
                    )}
                    {isOverllay && <img src={Over} alt="" className="ove" />}
                    {imageOverllay && <img src={Overllay} alt="" className="overlay" />}

                  </div>
                </a>
                <i className="fas fa-close" onClick={handleClose}></i>
              </>
            ) : (
              <div>
                {["mp4", "avi", "mkv", "mov", "webm"].includes(
                  additionalImage?.split(".")?.pop()?.toLowerCase()
                ) ? (
                  <video
                    width="100%"
                    height="165"
                    autoPlay
                    muted
                    playsInline
                    loop={true}
                    onLoadedMetadata={handleVideoDuration} // Set interval based on video duration
                  >
                    <source src={additionalImage} type="video/mp4" />
                  </video>
                ) : (
                  <img src={additionalImage} alt="Slide" />
                )}
                <i className="fas fa-close" onClick={handleClose}></i>
                {isOverllay && <img src={Over} alt="" className="ove" />}
                {imageOverllay && <img src={Overllay} alt="" className="overlay" />}

              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default BannerHome;
