import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Post from "../assets/images/food.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Image from "react-bootstrap/Image";
import { useCartStore } from "../store/useStore";
import { addToCart } from "../utils/cartUtils";
import axios from "../axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import { object } from "yup";
import ReactGA from "react-ga4";
import { ThemeContext } from "../context/ThemeContext";

function MealItem({
  item,
  restaurantId,
  groubs_extras,
  setGroubs_extras,
  groubs,
  setGroubs,
  groubsID,
  setGroubsID,
  groub_id,
  itemsID,
  setItemsID,
  hideBtn,
}) {
  // console.log("item02152547", item);
  //  itemsID, setItemsID;
  let itemgroub = item;
  item = item.item;
  // console.log("isOffCategory", restaurant_is_closed);
  // console.log("restaurant_is_closed", !isOffCategory || !restaurant_is_closed);

  // console.log("res", res);
  // const [enableClass, setEnableClass] = useState(true);
  // const new_design = localStorage.getItem("new_design");
  // const theme = new_design == "true";
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [showCartMeal, setshowCartMeal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extras, setExtras] = useState([]);
  const [priceOption, setPriceOption] = useState(0);
  const [checkedInputs, setcheckedInputs] = useState([]);

  // const isRequired = item?.extrasWithOptions[0]?.is_required;
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // console.log("itemitemitem", item);

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  const currency = localStorage.getItem("currency");
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Choose an item from the restaurant ",
      action: "Click",
      value: `item name: ${item?.name}}`,
    });
  };

  useEffect(() => {
    const checkboxes = document.getElementsByClassName("checkboxes");
    // console.log("checkboxes");
    Object.values(checkboxes).map((check) => {
      if (check.children[0].required == true) {
        check.children[0].setCustomValidity("Please select 1 option at least");
      }
    });
  }, []);

  useEffect(() => {
    // console.log("item?.extrasWithOptions?.length > 0");
  }, [show]);

  const handleCartClose = () => {
    setshowCartMeal(false);
    setCount(1);
    setExtras([]);
  };
  const handleCartShow = () => setshowCartMeal(true);
  const handleIncrease = () => {
    setCount((prev) => prev + 1);
  };
  const handleDecrease = () => {
    setCount((prev) => {
      if (prev <= 1) {
        return 1;
      }
      return prev - 1;
    });
  };

  const lang = localStorage.getItem("lang");
  // console.log("languahg", lang);
  // const SetCartItem = (item) => {
  //   const tempCart = [...JSON.parse(JSON.stringify(cart)), item];
  //   setCart(tempCart);
  // };
  // const [myObject, setMyObject] = useState([]);
  // const obje = [];
  const formData = new FormData();
  // const setExtramm = (id, idx) => {
  //   formData.append(`extras[${idx}]`, id);
  //   // setMyObject(...myObject, id);
  //   // console.log("myObjmmdmdmect", id);
  // };
  formData.append("item_id", item.id);
  formData.append("quantity", count);
  formData.append("restaurant_id", parseInt(restaurantId));
  //   useEffect(() => {
  //     const input_ch = document.getElementsByClassName("input_ch");
  // console.log("item?.extrasWithOptions?.length", item?.extrasWithOptions?.length);
  //   }, []);
  const [optionsIds, setOptionsIds] = useState({});

  const handleInvalid = (event, index) => {
    event.target.setCustomValidity("Please select 1 option at least");
  };

  const handelCheckInputs = (id) => {
    if (checkedInputs.includes(id)) {
      const filtered = groubsID.filter((temp) => {
        return temp !== id;
        // console.log("filtered", filtered);
      });
      setcheckedInputs(filtered);
    } else {
      setcheckedInputs((prev) => [id, ...prev]);
    }
    if (groubs_extras.includes(id)) {
      const filtered = groubsID.filter((temp) => {
        return temp !== id;
        // console.log("filtered", filtered);
      });
      setGroubs_extras(filtered);
    } else {
      setGroubs_extras((prev) => [id, ...prev]);
    }
  };
  // console.log("checkedInputs", checkedInputs);
  const handleHandleOptionsChanges = (
    event,
    optionName,
    optionId,
    type,
    price,
    idx,
    max_op
  ) => {
    event.target.setCustomValidity("");
    // console.log("max_op", max_op);

    if (type == "radio") {
      // if (state == "remove") {
      //   const { [optionName]: _, ...rest } = optionsIds;

      //   setOptionsIds(rest);
      // } else {
      setOptionsIds((prev) => ({
        ...prev,
        [optionName]: optionId,
      }));
      // }
    } else {
      const idex = document.getElementById(`idx${idx}`);
      var inputNodes = idex.getElementsByTagName("INPUT");
      var inputLang = idex.querySelectorAll(
        'input[type="checkbox"]:checked'
      ).length;
      // console.log("inputNodes", inputNodes);
      Object.values(inputNodes).map((inp) => {
        if (inputLang >= 1) {
          inp.required = false;
          inp.setCustomValidity("");
        } else if (inputLang == 0) {
          inp.required = true;
          inp.setCustomValidity("Please select 1 option at least");
        }
        if (inputLang == max_op) {
          // console.log("inputLang", inputLang);
          if (inp.checked == false) {
            inp.disabled = true;
          }
        } else {
          if (inp.checked == false) {
            inp.disabled = false;
          }
        }
      });

      // Check if the object is present in the array
      const index = extras.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(optionId)
      );
      if (index !== -1) {
        // If found, remove the object from the array
        extras.splice(index, 1);

        setPriceOption(priceOption - Number(price * count));
      } else {
        // If not found, add the object to the array
        extras.push(optionId);

        setPriceOption(priceOption + Number(price * count));
      }
    }
  };

  // console.log("price", priceOption);
  // console.log("optionsIds", Object.values(optionsIds));
  // useEffect(() => console.log("optionsIds", optionsIds, extras), [optionsIds]);

  // const objectOpt = [Object.values(optionsIds)]
  // console.log();
  let text_extras = [];
  const addToCartV2 = (e) => {
    const optionsArray = [...Object.values(optionsIds), ...extras];
    let formatArray = optionsArray.map((option) => {
      // setGroubs_extras([...groubs_extras, option.id]);
      return {
        extra_id: option.extra_id,
        option_id: option.id,
        price: option.price,
      };
    });

    const newGrop = groubs?.filter((item) => {
      return item.group_id != groub_id;
    });

    console.log("newGrop", newGrop);
    setGroubs([
      ...newGrop,
      {
        group_id: groub_id,
        item_id: itemgroub.item.id,
        quantity: count,
        extras: formatArray,
      },
    ]);
    // formatArray=[{groub}]
    // console.log("...Object.values(optionsIds), ...extras]", formatArray);
    e.preventDefault();

    if (groubsID.includes(groub_id)) {
      // const filtered = groubsID.filter((temp) => {
      //   return temp !== groub_id;
      //   // console.log("filtered", filtered);
      // });
      setGroubsID(groubsID);
    } else {
      setGroubsID((prev) => [groub_id, ...prev]);
    }
    // setGroubsID([...groubsID, groub_id]);
    setItemsID([...itemsID, itemgroub.item.id]);

    let extraText = document.getElementsByClassName("ex_class");
    if (extraText.length > 0) {
      Object.values(extraText).map((item) => {
        if (item.value != "") {
          text_extras.push({ extra_id: item.id, text: item.value });
        }
      });
    }
    // handleCartClose();
    setshowCartMeal(false);
    // setGroubs_extras([...groubs_extras, ...checkedInputs]);
    // console.log("groubs_extras*************", groubs_extras);
    // setLoading(true);
    // if (text_extras.length > 0) {
    //   axios
    //     .post(`/${room_uuid}/restaurant/order/order-item`, {
    //       restaurant_id: parseInt(restaurantId),
    //       item_id: item.id,
    //       quantity: count,
    //       extras: formatArray,
    //       text_extras: text_extras,
    //     })
    //     .then((res) => {
    //       setLoading(false);
    //       queryClient.invalidateQueries("checkout");
    //       const data = res.data.data;
    //       handleCartClose();
    //       setCartCount(cartCount + 1);

    //       console.log(res);
    //     });
    // } else {
    //   axios
    //     .post(`/${room_uuid}/restaurant/order/order-item`, {
    //       restaurant_id: parseInt(restaurantId),
    //       item_id: item.id,
    //       quantity: count,
    //       extras: formatArray,
    //     })
    //     .then((res) => {
    //       setLoading(false);
    //       queryClient.invalidateQueries("checkout");
    //       const data = res.data.data;
    //       handleCartClose();
    //       setCartCount(cartCount + 1);

    //       console.log(res);
    //     });
    // }

  };
  const setExtra = (name, type) => {

    // if (extras.length <= 2) {
    if (type == "radio") {
      setExtras([name]);
    } else {
      if (extras.includes(name)) {
        const tempExtras = JSON.parse(JSON.stringify(extras));
        const filtered = tempExtras.filter((temp) => {
          return temp !== name;
        });
        setExtras(filtered);
      } else {
        setExtras((prev) => [name, ...prev]);
      }
    }

    // }
  };

  const removeFromMeal = (id, itemId) => {
    const newGrop = groubs?.filter((item) => {
      return item.group_id != id;
    });

    setGroubs([...newGrop]);

    const newGropID = groubsID?.filter((item) => {
      return item != id;
    });

    setGroubsID([...newGropID]);

    const newitemID = itemsID?.filter((item) => {
      return item != itemId;
    });

    setItemsID([...newitemID]);
  };
  let newpice = 0;
  extras?.map((ext) => {
    newpice = newpice + ext.price;
  });
  newpice = newpice * count;
  return (
    <>
      {item ? (
        <Zoom>
          <div className="add-cart-post new">
            <div className="add-cart-post-inner">
              <div className={"d-none"}></div>
              <div
                onClick={handleShow}
                className="img-pnl position-relative navigate cover animated-background"
              // className="img-pnl animated-background"
              >
                <Image src={encodeURI(item.image)} alt="Post" />
              </div>
              <div
                className="txt-pnl navigate"
                onClick={() => {
                  if (!hideBtn) {
                    handleCartShow();
                  }
                }}
              >
                <h2
                //    className="animated-background"
                >
                  {lang == "EN" ? item?.display_name : item?.name}
                </h2>
                {item.restaurant_name && (
                  <h6 className="s_title">{item.restaurant_name}</h6>
                )}
                <p
                // className="animated-background"
                >
                  {item.description}
                </p>
                <div className="flex-div">
                  <h3 className={`${item.restaurant_name ? "" : "mt7"}`}>
                    {itemgroub.price == "0.00" ? (
                      <span style={{ fontSize: "10px" }}>
                        {t("Price upon selection")}
                      </span>
                    ) : (
                      `${currency} ${itemgroub.price}`
                    )}
                  </h3>

                  {hideBtn && (
                    <>
                      {itemsID.includes(item.id) ? (
                        <Button
                          onClick={() => removeFromMeal(groub_id, item.id)}
                          style={{ width: "115px" }}
                          disabled={
                            groubsID.includes(groub_id) &&
                            !itemsID.includes(item.id)
                          }
                          className="add-button"
                        >
                          {t("Remove from Meal")}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleCartShow}
                          disabled={
                            groubsID.includes(groub_id) &&
                            !itemsID.includes(item.id)
                          }
                          className="add-button"
                        >
                          {t("Add to Meal")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      ) : null}

      {item ? (
        <Modal
          show={show}
          // style={{ maxWidth: "430px" }}
          id={"restaurant-order-modal"}
          className="custom-width img p-2"
          onHide={handleClose}
        >
          <Button className="close-btn img" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body style={{ padding: "20px" }}>
            <div className="modal-img-container">
              <Image src={item.image} alt="Post" />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={show}
          // style={{ maxWidth: "430px" }}
          id={"restaurant-order-modal"}
          className={`${theme ? "food-modal" : ""} custom-width img`}
          onHide={handleClose}
        >
          <Button className="close-btn img" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body style={{ padding: "7px" }}>
            <div className="modal-img-container">
              <Image
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/restaurant_items/item1.png"
                }
                alt="Post"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {item ? (
        <Modal
          className={`${theme ? "food-modal" : ""} custom-width `}
          show={showCartMeal}
          onHide={handleCartClose}
          backdrop="static"
          keyboard={false}
        >
          <Button className="close-btn" onClick={handleCartClose}>
            {/* <i className="fa fa-times-circle-o"></i> */}
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body>
            <div className="modal-img-container">
              <Image src={item.image} alt="Post" />
            </div>
            <div className="modal-text-container sub-menu">
              <h2 className="text-1">
                {lang == "EN" ? item?.display_name : item?.name}
              </h2>
              <p className="text-2">
                {item.description ? item.description : ""}
              </p>
              <div className="price-container">
                <p>
                  {itemgroub.price == "0.00" ? (
                    <span style={{ fontSize: "10px" }}>
                      {t("Price upon selection")}
                    </span>
                  ) : (
                    `${currency} ${itemgroub.price}`
                  )}
                </p>
                <div className="count-order-amount">
                  <Button className="small" onClick={handleDecrease}>
                    <i className="fa fa-minus"></i>
                  </Button>
                  <p>{count}</p>
                  <Button className=" small" onClick={handleIncrease}>
                    <i className="fa fa-plus"></i>
                  </Button>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center cart-btn-container">
              <Button className="reg-btn small w-100">Add to Meal</Button>
            </div> */}
              <form action="" onSubmit={addToCartV2}>
                {item?.extrasWithOptions?.length > 0
                  ? item?.extrasWithOptions.map((opti, idx) => {
                    return (
                      <>
                        <div className="extras-heading" key={idx}>
                          <p className="text">{opti.name}</p>
                          <p className="required">
                            {" "}
                            {opti?.is_required ? t("Required") : ""}
                          </p>
                        </div>
                        {opti.extra_type_name == "text" && (
                          <div className="extra_input">
                            <input
                              type="text"
                              id={opti.extra_id}
                              className="ex_class"
                            />
                          </div>
                        )}
                        <div className="extras-items" id={`idx${idx}`}>
                          {opti?.option
                            ? opti?.option.map((extra, index) => {
                              return (
                                <div className="extras-item" id={index}>
                                  <p className="item-text">{extra.name}</p>
                                  <div className="res_options" key={index}>
                                    {extra?.option_has_price && (
                                      <p>{`+ ${extra.currency} ${extra.price}`}</p>
                                    )}

                                    {opti.extra_type_name == "radio" ? (
                                      <Form.Check
                                        type={opti.extra_type_name}
                                        name={opti.name}
                                        id={opti.name}
                                        className="item-check"
                                        required={opti.is_required}
                                        // disabled={
                                        //   extras.length >= 2 &&
                                        //   !extras.includes(extra.name)
                                        // }
                                        onChange={(event) =>
                                          handleHandleOptionsChanges(
                                            event,
                                            opti.name,
                                            (extra = {
                                              ...extra,
                                              extra_id: opti.extra_id,
                                            }),
                                            opti.extra_type_name,
                                            extra.price
                                          )
                                        }
                                        aria-label="radio 1"
                                      />
                                    ) : (
                                      <Form.Check
                                        type={opti.extra_type_name}
                                        name={extra.name}
                                        id={extra.name}
                                        className="item-check"
                                        required={
                                          index == 0
                                            ? opti.is_required
                                            : false
                                        }
                                        onInvalid={handleInvalid}
                                        onChange={(event) => {
                                          handleHandleOptionsChanges(
                                            event,
                                            opti.name,
                                            (extra = {
                                              ...extra,
                                              extra_id: opti.extra_id,
                                            }),
                                            opti.extra_type_name,
                                            extra.price,
                                            idx,
                                            opti.max_options
                                          );
                                          handelCheckInputs(extra.id);
                                        }}
                                        aria-label="radio 1"
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </>
                    );
                  })
                  : null}
                <div className="full-div mt-4 modal-add-cart">
                  {hideBtn && (
                    <Button
                      // onClick={() =>
                      //   addToCart({
                      //     props: { item, cart, setCart, count, handleCartClose },
                      //   })
                      // }
                      type="submit"
                      // onClick={addToCartV2}
                      className="reg-btn big w-100 flex-div"
                      disabled={loading}
                    >
                      <div className="plus-container">
                        <i className="fa fa-plus-square"></i>{" "}
                        <span>{t("Add to Meal")}</span>
                      </div>
                      <div>
                        {itemgroub.price == "0.00" && priceOption == 0 ? (
                          <span style={{ fontSize: "12px" }}>
                            {t("Price upon selection")}
                          </span>
                        ) : (
                          `${currency} ${parseFloat(
                            itemgroub.price * count + newpice
                          ).toFixed(2)}`
                        )}
                      </div>
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
export default MealItem;
